import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/database';
import 'firebase/compat/functions';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import 'firebase/compat/performance';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';
import { Types } from '../../context/DocumentReducer';

const remoteConfig = {
	service: {
		apiKey: process.env.NEXT_PUBLIC_API_KEY,
		authDomain: process.env.NEXT_PUBLIC_AUTH_DOMAIN,
		databaseURL: process.env.NEXT_PUBLIC_DATABASE_URL,
		projectId: process.env.NEXT_PUBLIC_PROJECT_ID,
		storageBucket: process.env.NEXT_PUBLIC_STORAGE_BUCKET,
		messagingSenderId: process.env.NEXT_PUBLIC_MESSAGING_SENDER_ID,
		appId: process.env.NEXT_PUBLIC_APP_ID,
		measurementId: process.env.NEXT_PUBLIC_MEASUREMENT_ID,
	},
};
const localConfig = {
	service: {
		...remoteConfig.service,
		databaseURL: 'http://localhost:9000?ns=bron-id-sandbox',
	},
	firestore: {
		host: 'localhost:8080',
		ssl: false,
	},
};

const isDevelopment = process.env.NODE_ENV === 'development';

class Firebase {
	constructor() {
		const serviceConfig = isDevelopment ? localConfig.service : remoteConfig.service;

		// eslint-disable-next-line
		const firebaseInstance = !firebase.apps.length ? firebase.initializeApp(serviceConfig) : firebase.app();

		// Create a ReCaptchaEnterpriseProvider instance using your reCAPTCHA Enterprise
		// site key and pass it to initializeAppCheck().
		const notInServer = typeof window !== 'undefined';
		if (notInServer) {
			if (isDevelopment) {
				// use a debug token for localhost, or print the generated debug token to console
				self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.NEXT_PUBLIC_REACT_APP_APPCHECK_DEV_TOKEN || true;
			}
			const appCheck = initializeAppCheck(firebaseInstance, {
				provider: new ReCaptchaEnterpriseProvider(process.env.NEXT_PUBLIC_APPCHECK_RECAPTCHA_KEY), // reCAPTCHA Enterprise site key
				isTokenAutoRefreshEnabled: true, // Set to true to allow auto-refresh.
			});
		}

		// can this use just app.functions() ??? instead of the instance
		// eslint-disable-next-line no-unused-vars
		// const app = firebaseInstance.functions().useFunctionsEmulator('http://localhost:5001');

		// use specified region for functions
		const firebaseRegion_sydney = 'australia-southeast1';
		const regionalFunctions = firebaseInstance.functions(firebaseRegion_sydney);

		const runOnlyOnce_runOnlyOnServer = isDevelopment && (typeof window === 'undefined' || !window['_init']);
		if (runOnlyOnce_runOnlyOnServer) {
			if (typeof window !== 'undefined') {
				window['_init'] = true;
			}

			// Firestore emulator
			firebaseInstance.firestore().useEmulator('localhost', 8080);
			firebaseInstance.firestore().settings({ experimentalAutoDetectLongPolling: true, merge: true });
			// Realtime emulator
			firebaseInstance.database().useEmulator('localhost', 9000);
			// Auth emulator
			firebaseInstance.auth().useEmulator('http://localhost:9099/');
			// Storage emulator
			firebaseInstance.storage().useEmulator('localhost', 9199);

			// Function emulator
			regionalFunctions.useEmulator('localhost', 5001); // const app =
		}

		this.auth = firebase.auth();
		this.db = firebase.database();
		this.firestore = firebase.firestore();
		this.functions = regionalFunctions;
		this.storage = firebase.storage();
		this.perf = typeof window !== 'undefined' ? firebase.performance() : null;
	}

	// *** Auth API ***

	doSignOut = () => this.auth.signOut();

	doCreateUserWithAnonymous = () => this.auth.signInAnonymously();
	// doCreateUserWithEmailAndPassword = (email, password) => this.auth.createUserWithEmailAndPassword(email, password);
	// doSignInWithEmailAndPassword = (email, password) => this.auth.signInWithEmailAndPassword(email, password);
	// doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
	// doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

	// *** Queue API ***

	// incomingQueueWrite = uid => actionId => this.db.ref(`actionQueue/incoming/${uid}/${actionId}/write_only`);

	// incomingQueueRead = uid => actionId => this.db.ref(`actionQueue/incoming/${uid}/${actionId}/read_only`);

	// *** User API ***

	user = uid => this.db.ref(`users/${uid}`);

	users = () => this.db.ref('users');

	// *** Functions API ***

	callRequestEkycAccess = data => {
		const firebaseFunction = this.functions.httpsCallable('requestEkycAccess');
		return firebaseFunction(data);
	};

	uploadImage = (dispatch, context, documentName, imageType, metadata = {}) => {
		function getImageToUpload() {
			// const currentDocument = context.currentDocument;
			const { images } = context.documents[documentName];
			return images[imageType].data;
		}
		function setUploadProgress(uploadProgressPercentage) {
			// console.log('Uploading... :' + uploadProgressPercentage + '% done');

			dispatch({
				type: Types.UpdateUploadProgress,
				payload: {
					documentId: documentName,
					uploadProgressPercentage,
				},
			});
		}

		return new Promise((fulfill, reject) => {
			const imageBase64 = getImageToUpload();
			const imageRef = this.storage.ref(context.url);
			const uploadTask = imageRef.putString(imageBase64, 'data_url', { customMetadata: metadata });
			// console.log('>>>> Upload started.');

			// Register three observers:
			// 1. 'state_changed' observer, called any time the state changes
			// 2. Error observer, called on failure
			// 3. Completion observer, called on successful completion
			const reportUploadProgress = snapshot => {
				function limitBetween0and99(num) {
					try {
						const MIN = 1;
						const MAX = 99;
						const parsed = parseInt(num, 10);
						return Math.min(Math.max(parsed, MIN), MAX);
					} catch (exception) {
						console.log('exception', exception);
					}

					return 1;
				}

				// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
				const uploadProgressPercentage = limitBetween0and99((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
				setUploadProgress(uploadProgressPercentage);
				// switch (snapshot.state) {
				// 	case firebase.storage.TaskState.PAUSED: // or 'paused'
				// 		console.log('>>>> Upload is paused.');
				// 		break;
				// 	case firebase.storage.TaskState.RUNNING: // or 'running'
				// 		console.log('>>>> Upload is running.');
				// 		break;
				// }
			};
			const handleUploadError = error => {
				// TODO: log error to StackDriver or Sentry
				console.log('>>>> Upload error:', error);

				// unlock button and signal error
				setUploadProgress(-1);
				reject('Could not upload image.');

				// A full list of error codes is available at
				// https://firebase.google.com/docs/storage/web/handle-errors
				// switch (error.code) {
				// 	case 'storage/unauthorized':
				// 		console.log('unauthorized');
				// 		// User doesn't have permission to access the object
				// 		break;
				//
				// 	case 'storage/canceled':
				// 		console.log('canceled');
				// 		// User canceled the upload
				// 		break;
				//
				// 	case 'storage/unknown':
				// 		console.log('unknown');
				// 		// Unknown error occurred, inspect error.serverResponse
				// 		break;
				// }
			};
			const handleUploadComplete = () => {
				// Upload completed successfully, now we can get the download URL
				uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
					// upload is complete when we can confirm the user can read the file
					// this can also be moved outside the getDownloadUrl because by definition
					// it is called when the upload has finished
					// console.log('>>>> Upload is completed.');
					// console.log('Download URL:', downloadURL);
					setUploadProgress(100);
					fulfill(true);
				});
			};
			uploadTask.on(
				firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
				reportUploadProgress,
				handleUploadError,
				handleUploadComplete,
			);
		});
	};

	uploadVideo = (dispatch, context, documentName, imageType, blob) => {
		function getBlobToUpload() {
			// const currentDocument = context.currentDocument;
			// const { images } = context.documents[documentName];
			// return images[imageType].data;
			return blob;
		}
		function setUploadProgress(uploadProgressPercentage) {
			// console.log('Uploading... :' + uploadProgressPercentage + '% done');

			dispatch({
				type: Types.UpdateUploadProgress,
				payload: {
					documentId: documentName,
					uploadProgressPercentage,
				},
			});
		}

		return new Promise((fulfill, reject) => {
			const fileBlob = getBlobToUpload();
			const blobRef = this.storage.ref(context.url);
			const uploadTask = blobRef.put(fileBlob);

			// Register three observers:
			// 1. 'state_changed' observer, called any time the state changes
			// 2. Error observer, called on failure
			// 3. Completion observer, called on successful completion
			const reportUploadProgress = snapshot => {
				function limitBetween0and99(num) {
					try {
						const MIN = 1;
						const MAX = 99;
						const parsed = parseInt(num, 10);
						return Math.min(Math.max(parsed, MIN), MAX);
					} catch (exception) {
						console.log('exception', exception);
					}

					return 1;
				}

				// Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
				const uploadProgressPercentage = limitBetween0and99((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
				setUploadProgress(uploadProgressPercentage);
				// switch (snapshot.state) {
				// 	case firebase.storage.TaskState.PAUSED: // or 'paused'
				// 		console.log('>>>> Upload is paused.');
				// 		break;
				// 	case firebase.storage.TaskState.RUNNING: // or 'running'
				// 		console.log('>>>> Upload is running.');
				// 		break;
				// }
			};
			const handleUploadError = error => {
				// TODO: log error to StackDriver or Sentry
				console.log('>>>> Upload error:', error);

				// unlock button and signal error
				setUploadProgress(-1);
				reject('Could not upload image.');

				// A full list of error codes is available at
				// https://firebase.google.com/docs/storage/web/handle-errors
				// switch (error.code) {
				// 	case 'storage/unauthorized':
				// 		console.log('unauthorized');
				// 		// User doesn't have permission to access the object
				// 		break;
				//
				// 	case 'storage/canceled':
				// 		console.log('canceled');
				// 		// User canceled the upload
				// 		break;
				//
				// 	case 'storage/unknown':
				// 		console.log('unknown');
				// 		// Unknown error occurred, inspect error.serverResponse
				// 		break;
				// }
			};
			const handleUploadComplete = () => {
				// Upload completed successfully, now we can get the download URL
				uploadTask.snapshot.ref.getDownloadURL().then(downloadURL => {
					// upload is complete when we can confirm the user can read the file
					// this can also be moved outside the getDownloadUrl because by definition
					// it is called when the upload has finished
					// console.log('>>>> Upload is completed.');
					// console.log('Download URL:', downloadURL);
					setUploadProgress(100);
					fulfill(true);
				});
			};
			uploadTask.on(
				firebase.storage.TaskEvent.STATE_CHANGED, // or 'state_changed'
				reportUploadProgress,
				handleUploadError,
				handleUploadComplete,
			);
		});
	};
}

export default Firebase;
