import DocumentSelectionHelpers, { IDocumentsUploadStatus, UPLOAD_STATUS } from '../components/DocumentSelection/DocumentSelectionHelpers';
import { DocumentActionTypes, ImageMetadataType, Types } from './DocumentReducer';
import Firebase from '../components/Firebase';

const omitCurrentDocument = (currentContext: IDocumentsUploadStatus): IDocumentsUploadStatus => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { currentDocument: omit, ...rest } = currentContext;
	return rest;
};
const saveContextToLocalStorage = (context: IDocumentsUploadStatus) => {
	sessionStorage.setItem('localContext', JSON.stringify(context));
};

const resetCurrentDocument = (currentContext: IDocumentsUploadStatus): IDocumentsUploadStatus => {
	const newContext = omitCurrentDocument(currentContext);

	saveContextToLocalStorage(newContext);
	return newContext;
};

const generateBlankDocument = (currentContext: IDocumentsUploadStatus, documentId: string): IDocumentsUploadStatus => {
	const documents = currentContext?.documents;
	const resetImages = {
		[documentId]: {
			images: {},
		},
	};

	return {
		...currentContext,
		documents: {
			...documents,
			...resetImages,
		},
		currentDocument: documentId,
	};
};

const updateEkycUuid = (currentContext: IDocumentsUploadStatus, ekycUuid: string, documentGroup: string): IDocumentsUploadStatus => ({
	...currentContext,
	ekycUuid,
	documentGroup,
});

const setCurrentDocument = (currentContext: IDocumentsUploadStatus, currentDocumentId: string): IDocumentsUploadStatus => {
	const newContext = generateBlankDocument(currentContext, currentDocumentId);

	saveContextToLocalStorage(newContext);
	return newContext;
};

const setEkycUuid = (currentContext: IDocumentsUploadStatus, ekycUuid: string, documentGroup = 'default'): IDocumentsUploadStatus => {
	const newContext = updateEkycUuid(currentContext, ekycUuid, documentGroup);

	saveContextToLocalStorage(newContext);
	return newContext;
};

const loadDocumentsContextFromLocalStorage = () => {
	const defaultDocumentContext: IDocumentsUploadStatus = {};
	let localContext;

	// only do this in the browser (not server-side)
	if (process.browser) {
		// get the values from sessionStorage
		const sessionStorageValue = sessionStorage.getItem('localContext');

		// if there is anything there, parse it and store it in a variable
		if (sessionStorageValue !== null) {
			localContext = JSON.parse(sessionStorageValue);
		}
	}

	// load values from sessionStorage or initial value if there is none
	return { documentsContext: localContext || defaultDocumentContext };
};

export type ImageObjectType = {
	imageType: string;
	imageBase64: string;
};
const addImageToDocumentContext = (currentContext: IDocumentsUploadStatus, imageObject: ImageObjectType) => {
	const { imageType, imageBase64 } = imageObject;
	const { currentDocument = '', documents } = currentContext;
	const defaultDocumentValue = { images: {} };
	const existingValue = documents?.[currentDocument] ?? defaultDocumentValue;

	// add the screenshot to the images
	const newContext = {
		...currentContext,
		documents: {
			...documents,
			[currentDocument]: {
				...existingValue,
				images: { ...existingValue.images, [imageType]: { imageType, dataType: 'base64', data: imageBase64 } },
				uploadProgressPercentage: 0,
			},
		},
	};

	saveContextToLocalStorage(newContext);
	return newContext;
};
const discardImageFromDocumentContext = (currentContext: IDocumentsUploadStatus, imageType: string) => {
	const { currentDocument = '', documents } = currentContext;
	const defaultDocumentValue = { images: {} };
	const existingDocument = documents?.[currentDocument] ?? defaultDocumentValue;
	const existingValue = existingDocument?.images ?? defaultDocumentValue;
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const { [imageType]: discardedImage, ...restExistingValue } = existingValue;

	// remove the screenshot from the images
	const newContext = {
		...currentContext,
		documents: {
			...documents,
			[currentDocument]: {
				...existingDocument,
				images: {
					...restExistingValue,
				},
				uploadProgressPercentage: 0,
			},
		},
	};

	saveContextToLocalStorage(newContext);
	return newContext;
};

const setDocumentImage = (currentContext: IDocumentsUploadStatus, imageObject: ImageObjectType): IDocumentsUploadStatus => {
	const newContext = addImageToDocumentContext(currentContext, imageObject);

	return newContext;
};

const discardDocumentImage = (currentContext: IDocumentsUploadStatus, imageType: string, redirect: () => void): IDocumentsUploadStatus => {
	const newContext = discardImageFromDocumentContext(currentContext, imageType);

	saveContextToLocalStorage(newContext);
	redirect();
	return newContext;
};

const async_image_upload = async (
	dispatch,
	currentContext: IDocumentsUploadStatus,
	documentId: string,
	imageType: string,
	metadata: ImageMetadataType,
) => {
	function reportUploadStatus(uploadStatus: string) {
		dispatch({
			type: Types.UpdateUploadStatus,
			payload: { documentId, imageType, uploadStatus },
		});
	}

	try {
		// const timestamp = new Date().getTime();
		const firebase = new Firebase();
		const userId = firebase.auth?.currentUser?.uid;
		// const imageUrl = `/users/${userId}/${currentContext.ekycUuid}/${documentId}/${timestamp}-${imageType}.jpg`;
		const imageUrl = `/users/${userId}/${currentContext.ekycUuid}/${documentId}/${documentId}-${imageType}.jpg`;

		await firebase.uploadImage(dispatch, { ...currentContext, url: imageUrl }, documentId, imageType, metadata);

		reportUploadStatus(DocumentSelectionHelpers.UPLOAD_STATUS.COMPLETED);
	} catch (exception) {
		reportUploadStatus(DocumentSelectionHelpers.UPLOAD_STATUS.ERROR);
	}
};

const async_video_upload = async (dispatch, currentContext: IDocumentsUploadStatus, documentId: string, imageType: string, blob: string) => {
	function reportUploadStatus(uploadStatus) {
		dispatch({
			type: Types.UpdateUploadStatus,
			payload: { documentId, imageType, uploadStatus },
		});
	}

	try {
		// const timestamp = new Date().getTime();
		const firebase = new Firebase();
		const userId = firebase.auth?.currentUser?.uid;
		// const videoUrl = `/users/${userId}/${currentContext.ekycUuid}/${documentId}/${timestamp}-${imageType}.webm`;
		const videoUrl = `/users/${userId}/${currentContext.ekycUuid}/${documentId}/${documentId}-video.webm`;

		await firebase.uploadVideo(dispatch, { ...currentContext, url: videoUrl }, documentId, imageType, blob);

		reportUploadStatus(DocumentSelectionHelpers.UPLOAD_STATUS.COMPLETED);
	} catch (exception) {
		reportUploadStatus(DocumentSelectionHelpers.UPLOAD_STATUS.ERROR);
	}
};

const removeImageDataFromLocalContext = (
	document: {
		images: {
			[key: string]: {
				imageType: string;
				dataType: string;
				data: string;
			};
		};
	},
	imageType: string,
) => {
	const { images = {} } = document;
	const { [imageType]: removedImage, ...restImages } = images;
	const { imageType: removedImageType, dataType: removedDataType, data: removedData } = removedImage;

	return {
		...document,
		images: {
			...restImages,
			[imageType]: {
				imageType: removedImageType,
				dataType: removedDataType,
				data: null,
				status: 'uploaded',
			},
		},
	};
};

const updateUploadStatus = (
	currentContext: IDocumentsUploadStatus,
	documentId: string,
	imageType: string,
	uploadStatus: UPLOAD_STATUS,
): IDocumentsUploadStatus => {
	try {
		const { documents } = currentContext;
		const uploadingDocument = (documents && documents[documentId]) || {};

		const nextDocument =
			uploadStatus === 'completed' && imageType ? removeImageDataFromLocalContext(uploadingDocument, imageType) : uploadingDocument;

		const newContext = {
			...currentContext,
			documents: {
				...documents,
				[documentId]: {
					...nextDocument,
					uploadStatus,
				},
			},
		};

		saveContextToLocalStorage(newContext);
		return newContext;
	} catch (exception) {
		// eslint-disable-next-line no-console
		console.log('Exception while updating context;', exception);
		return currentContext;
	}
};

const uploadDocumentImages = (
	dispatch: React.Dispatch<DocumentActionTypes>,
	currentContext: IDocumentsUploadStatus,
	documentId: string,
	imageType: string,
	metadata: ImageMetadataType,
): IDocumentsUploadStatus => {
	const newContext = updateUploadStatus(currentContext, documentId, imageType, DocumentSelectionHelpers.UPLOAD_STATUS.IN_PROGRESS);

	async_image_upload(dispatch, currentContext, documentId, imageType, metadata);

	return newContext;
};

const uploadLivenessVideo = (
	dispatch: React.Dispatch<DocumentActionTypes>,
	currentContext: IDocumentsUploadStatus,
	documentId: string,
	imageType: string,
	blob: string,
): IDocumentsUploadStatus => {
	const newContext = updateUploadStatus(currentContext, documentId, imageType, DocumentSelectionHelpers.UPLOAD_STATUS.IN_PROGRESS);

	async_video_upload(dispatch, currentContext, documentId, imageType, blob);

	return newContext;
};

const updateUploadProgress = (
	currentContext: IDocumentsUploadStatus,
	documentId: string,
	uploadProgressPercentage: number,
): IDocumentsUploadStatus => {
	const { documents = {} } = currentContext;
	const uploadingDocument = documents[documentId];

	const newContext = {
		...currentContext,
		documents: {
			...documents,
			[documentId]: {
				...uploadingDocument,
				uploadProgressPercentage,
			},
		},
	};

	saveContextToLocalStorage(newContext);
	return newContext;
};

const signOut = () => {
	const firebase = new Firebase();
	firebase.doSignOut();
};

const eraseContext = (/* currentContext: IDocumentsUploadStatus */): IDocumentsUploadStatus => {
	const newContext = {};

	saveContextToLocalStorage(newContext);
	signOut();
	return newContext;
};

export default {
	loadDocumentsContextFromLocalStorage,
	resetCurrentDocument,
	setCurrentDocument,
	setEkycUuid,
	setDocumentImage,
	discardDocumentImage,
	uploadDocumentImages,
	uploadLivenessVideo,
	eraseContext,
	updateUploadStatus,
	updateUploadProgress,
};
